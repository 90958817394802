@import "../base.css";

header {
    border-bottom: 1px solid #aaa;
    margin-bottom: 3rem;
}

#language-dropdown {
    text-transform: uppercase;
}

.lang-nav .dropdown-toggle {
    position: relative;
    top: 2px;
    color: #666;
    padding: 0;
}
.lang-nav .dropdown-toggle::after {
    display: none;
}
.lang-nav .dropdown-toggle:focus {
    box-shadow: none;
}
.lang-nav .dropdown-toggle i.fa {
    margin-right: 0.2rem;
}
.lang-nav .dropdown-menu {
    border-radius: 2px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    /* font-size: 13px; */
}
.lang-nav .dropdown-menu button {
    border: 0;
    background: none;
}

.centered-content {
    margin: 0 auto;
    max-width: 400px;
}

h2 {
    text-align: center;
    font-size: 1.7rem;
    margin-bottom: 2rem;
    font-weight: normal;
}

.form-login {
    margin-bottom: 3rem;
}

footer {
    border-top: 1px solid #aaa;
    margin-top: 5rem;
    padding-top: 1rem;
    color: #999;
    font-size: 0.9rem;
}

footer a {
    color: #999;
    text-decoration: underline;
}

.content {
    min-height: 500px;
    max-width: 800px;
    margin: 0 auto;
}

.sign-up-link {
    font-size: 1.3rem;
    text-decoration: underline;
}

.code-welcome,
.invite-welcome {
    text-align: center;
    margin-bottom: 2rem;
}

.code-display,
.org-name-display {
    font-size: 40px;
    font-weight: bold;
    color: #c0c0c0;
    position: relative;
}

.form-signup .svg-captcha {
    margin-bottom: 10px;
}

.go-to-dash-link {
    font-size: 1.3rem;
    text-decoration: underline;
}

.page-404-text {
    margin-bottom: 70px;
}

@font-face {
    font-family: "droid-sans";
    src: url("./fonts/DroidSans.eot?#iefix") format("embedded-opentype"),
        url("./fonts/DroidSans.woff") format("woff"),
        url("./fonts/DroidSans.ttf") format("truetype"),
        url("./fonts/DroidSans.svg#DroidSans") format("svg");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "droid-sans";
    src: url("./fonts/DroidSans-Bold.eot?#iefix") format("embedded-opentype"),
        url("./fonts/DroidSans-Bold.woff") format("woff"),
        url("./fonts/DroidSans-Bold.ttf") format("truetype"),
        url("./fonts/DroidSans-Bold.svg#DroidSans-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Droid Sans";
    src: url("./fonts/DroidSans.eot?#iefix") format("embedded-opentype"),
        url("./fonts/DroidSans.woff") format("woff"),
        url("./fonts/DroidSans.ttf") format("truetype"),
        url("./fonts/DroidSans.svg#DroidSans") format("svg");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Droid Sans";
    src: url("./fonts/DroidSans-Bold.eot?#iefix") format("embedded-opentype"),
        url("./fonts/DroidSans-Bold.woff") format("woff"),
        url("./fonts/DroidSans-Bold.ttf") format("truetype"),
        url("./fonts/DroidSans-Bold.svg#DroidSans-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
}

html {
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
}

body {
    font-family: droid-sans;
    color: #444;
}

a {
    color: #3191b0;
}
a:hover {
    color: #23527c;
}

label {
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 1rem;
}

.form-control {
    border-radius: 2px;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.form-control:focus {
    border-color: #66afe9;
    outline: 0;
}

input[type="text"],
input[type="email"],
input[type="password"] {
    height: calc(1.3em + 1rem + 2px);
    line-height: 1.3em;
}

input.short-input {
    max-width: 200px;
}

@media (min-width: 576px) {
    .col-form-label {
        text-align: right;
        line-height: 1.7em;
    }
}

.col-form-label {
    align-self: center;
}

.help-text {
    margin-top: 2px;
    color: #737373;
    display: block;
    font-size: 0.9rem;
}

label.not-required {
    font-weight: normal;
    color: #777;
}

input[type="text"].form-control-lg,
input[type="email"].form-control-lg,
input[type="password"].form-control-lg {
    height: calc(1.7em + 1rem + 2px);
    line-height: 1.7em;
    font-size: 1rem;
}

.btn.btn-primary {
    background-color: #1e8bc3;
    border: 1px solid rgba(0, 0, 0, 0.5);
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
    text-transform: uppercase;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.07),
        inset 0 1px 0 0 rgba(255, 255, 255, 0.14), 0px 0 1px 0 rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    background-clip: padding-box;
    text-align: center;
    transition: all 0.25s ease;
    padding: 0.8rem 1.5rem;
}

.btn.btn-primary:hover {
    background-color: #3fa9d4;
    border-color: #204d74;
}

.btn-link {
    color: #1e8bc3;
    border: none;
    padding: 0;
    margin: 0;
    /* text-decoration: none !important; */
    background-color: transparent;
}

.btn-link:hover {
    color: #1e8bc3;
    text-decoration: underline;
}
